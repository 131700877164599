<template>
  <login titulo="CONSIGNATÁRIA" />
</template>

<script>
import login from '@/components/login/login'

export default {
  name: 'LoginIndex',

  components: {
    login,
  },

  created() {
    document.title = 'PBConsig - Consignatária'
  },
}
</script>
